import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-container relative" }
const _hoisted_2 = {
  class: "absolute -top-0 right-12 text-gray-400 z-100",
  "data-aos": "fade-left",
  "data-aos-duration": "1000"
}
const _hoisted_3 = { class: "flex w-full items-center justify-center my-10" }
const _hoisted_4 = { class: "max-w-screen-2xl w-full mx-auto" }
const _hoisted_5 = { class: "lg:w-3/3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["TheHeader"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["TheClock"])
      ]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["TheStep"], {
        currentStep: $setup.currentStep,
        steps: $setup.accountRegistrationProgress
      }, null, 8, ["currentStep", "steps"])
    ]),
    _createVNode($setup["TenantDetailForm"], { "is-show-status-remark": true }),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        ($props.showInvitations)
          ? (_openBlock(), _createBlock($setup["BrandOwnerTable"], { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode($setup["TheFooter"], { class: "page-footer" })
  ]))
}